import * as React from 'react'
import { graphql, PageProps } from 'gatsby'
import { BulletPoints } from '../components/BulletPoints'
import Image from '../components/Image'
import { Layout } from '../components/Layout'
import { CallToAction } from '../components/Sections/CallToAction'
import { Card } from '../components/Sections/Card'

type NosotrosPageProps = PageProps<Queries.NosotrosPageQuery>

const NosotrosPage = ({
  data: {
    directus: { page, layout },
  },
}: NosotrosPageProps) => {
  if (!page) {
    return null
  }

  return (
    <Layout pageHeader={page.page_header}>
      {page.history_card && (
        <Card className="my-10 lg:my-16" {...page.history_card} />
      )}

      <div className="bg-brand-light-gray py-10 lg:py-16">
        <div className="container flex justify-center items-center space-x-24">
          <BulletPoints
            className="lg:w-2/3 max-w-2xl lg:max-w-auto"
            points={page.bullet_points}
          />
          {page.bullet_points_image && (
            <div className="w-1/3 hidden lg:block">
              <Image image={page.bullet_points_image} alt="" />
            </div>
          )}
        </div>
      </div>

      {page.experience_card && (
        <Card className="my-16" {...page.experience_card} />
      )}

      {layout?.contact_banner && <CallToAction {...layout.contact_banner} />}
    </Layout>
  )
}

export default NosotrosPage

export { PageHead as Head } from '../components/PageHead'

export const query = graphql`
  query NosotrosPage {
    directus {
      page: page_nosotros {
        meta_description
        page_header {
          ...PageHeader
        }
        history_card {
          ...CardComponent
        }
        bullet_points_image {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        bullet_points {
          bullet_points_id {
            ...BulletPoints
          }
        }
        experience_card {
          ...CardComponent
        }
      }
      layout {
        contact_banner {
          ...CallToActionComponent
        }
      }
    }
  }
`
