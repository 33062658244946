import { graphql } from 'gatsby'
import * as React from 'react'
import Image from './Image'

export type BulletPoint = {
  id: Maybe<string>
  icon: Maybe<DirectusImage>
  title: Maybe<string>
  description: Maybe<string>
}

type BulletPointDirectus = {
  bullet_points_id: Maybe<BulletPoint>
}

export type BulletPointsProps = {
  points: Maybe<ReadonlyArray<Maybe<BulletPointDirectus>>>
  className?: string
}

export const BulletPoints = ({ className, points }: BulletPointsProps) => {
  if (!points) {
    return null
  }

  return (
    <div className={className}>
      <div className="flex flex-col justify-center space-y-10">
        {points.map((point) => {
          if (!point?.bullet_points_id?.icon || !point.bullet_points_id.title) {
            return
          }

          const { id, icon, title, description } = point.bullet_points_id

          return (
            <div key={id} className="sm:flex sm:items-center">
              <div className="mr-2 sm:mt-0 sm:mr-4 lg:mr-8 float-left sm:float-none sm:flex-shrink-0">
                <div className="sm:rounded-full sm:bg-white sm:p-6 lg:p-8">
                  <Image
                    className="w-7 h-7 sm:w-16 sm:h-16"
                    image={icon}
                    alt={title}
                    objectFit="contain"
                  />
                </div>
              </div>
              <div>
                <h4 className="text-xl font-semibold text-brand-dark-blue">
                  {title}
                </h4>
                <div
                  className="prose-basic"
                  dangerouslySetInnerHTML={{
                    __html: description || '',
                  }}
                />
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export const query = graphql`
  fragment BulletPoints on DirectusData_bullet_points {
    id
    icon {
      id
      imageFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    title
    description
  }
`
